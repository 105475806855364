* {
  margin: 0;
  padding: 0;
  

}

.landing-app {
  background-color: #313340;
  height: 100vh;
}

.typewriter {
  display: flex;
  flex-direction: column;
  width: 40%;
  border: 4px solid #FD134D;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: absolute;
  top: 30%;
  left: 30%;
  background-color: rgb(253, 19, 77, 0.25);
}

Link {
  background-color:#313340;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  color: #FD134D;
  text-decoration: none;
  margin-right: auto;
  margin-left: auto;
}
.type_1,
.type_2,
.type_3 {
  white-space: nowrap; 
  overflow: hidden; 
  margin: 0.5rem auto;
  font-family: 'Oswald', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: #6F7073;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon-bar {
  position: fixed;
  top: 50%;
  
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  
}

/* Style the icon bar links */
.icon-bar a {
  display: flex;
  flex-direction: column;
  text-align: center;
 
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */

.twitter {
  background: #55ACEE;
  color: white;
  padding: 16px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.github {
  background: #313340;
color: white;
padding: 16px;
}

.linkedin {
  background: #007bb5;
  color: white;
  padding: 16px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.icon-bar a:hover {
  font-size: 30px;
}


@media only screen and (max-width: 1280px) {
  .typewriter {
    width: 50%;
  }

}

@media only screen and (max-width: 1024px) {
  .typewriter {
    display: flex;
    flex-direction: column;
    width: 50%;
    border: 4px solid #FD134D;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: absolute;
    top: 25%;
    left: 25%;
    background-color: rgb(253, 19, 77, 0.25);
  }
  .icon-bar a {
    display: block;
    text-align: center;
   padding: 13px;
    transition: all 0.3s ease;
    color: white;
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .typewriter {
    display: flex;
    flex-direction: column;
    width: 60%;
    border: 4px solid #FD134D;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: absolute;
    top: 25%;
    left: 20%;
    background-color: rgb(253, 19, 77, 0.25);
  }
}

@media only screen and (max-width: 425px) {
  .typewriter {
    display: flex;
    flex-direction: column;
    width: 95%;
    border: 4px solid #FD134D;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: absolute;
    top: 20%;
    left: 2%;
    background-color: rgb(253, 19, 77, 0.25);
  }
  .icon-bar {
    position: fixed;
   top: 92%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    
  }
  .icon-bar a {
    display: block;
    text-align: center;
   padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 16px;
  }
}