body {
  margin: 0;
  padding: 0;
  background-color:#313340;
}

.projects-section {
  background-color: #313340;
  font-family: 'Oswald', sans-serif;
  height: 150%;
  margin-bottom: 5rem;


}

.project-title {
  padding-top: 5rem;
  margin-left: 5rem;
width: 100%;
}

.project-title h1 {
  color: rgb(253, 19, 77, 0.5);
  
}

.recent-projects, .collaborations {
  display: flex;
  flex-direction: row;
}


.fav-fonts,
.house-hero,
.tutor-me,
.When-RU-Free,
.luna,
.device-loaner,
.zodaily,
.katiuska,
.BBM {
  display: flex;
  flex-direction: column;
  width: 100%;

 
  padding-top: 5rem;

}

.BBM, .zodaily, .tutor-me {
  margin-left: 8rem;
}


.mobile {
  height: 150px;
  width: 75px;
  box-shadow: 0.5rem 0.5rem black;
  margin-left: -7rem;
}

.laptop {
  height: 200px;
  width: 300px;
  box-shadow: 0.5rem 0.5rem black, -0.5rem  ;
}

.caption {
  width: 75%;
  text-align: center;
}

.caption h3 {
  font-size: 2rem;
  color: #6F7073;
  font-weight: bold;
  
}

.collaborations h3 {
  padding-bottom: 2rem;
}

.caption h4 {
  color: #FD134D;
  font-weight: bold;
}

.caption p {
  font-size: 1.25rem;
  color: #6F7073;
  font-weight: bold;
  margin-top: .5rem;
  margin-bottom: 2rem;
}

.caption a {
  background-color: #6F7073;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1.5rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: #FD134D;
  font-weight: bold;
  
}

.live{
  margin-right: 7rem;
  
}


@media only screen and (max-width: 1024px) {
  .recent-projects, .collaborations {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  }
  .mobile {
    height: 100px;
    width: 50px;
    position: absolute;
    margin-top: 5rem;
    margin-left: -7rem;
    
    
    box-shadow: 0.5rem 0.5rem black;
  }

  .laptop {
    height: 150px;
    width: 250px;
    box-shadow: 0.5rem 0.5rem black, -0.5rem ;
  }

  .fav-fonts,
  .house-hero,
  .tutor-me,
  .When-RU-Free,
  .luna,
  .device-loaner,
  .zodaily,
.katiuska, .BBM {
    width: 50%;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
  }

  .caption {
    width: 75%;
    
    margin-top: 2rem;
  }

  .caption h3 {
    font-size: 1.75rem;
    color: #6F7073;;
    font-weight: bold;
    
  }

  .caption p {
    font-size: 1rem;
    color: #6F7073;
    font-weight: bold;
    margin-top: 1rem;
    
  }

  .caption li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 0.8rem;
  }

  .caption ul {
    list-style: none;
    margin-left: 1rem;
  }

  .caption a {
    background-color: #6F7073;
    text-decoration: none;
    font-size: 1rem;
    padding: 1rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    color: #FD134D;
    font-weight: bold;
  }

  .BBM, .zodaily, .tutor-me {
    margin-left: 5rem;
  }
}

@media only screen and (max-width: 768px) {
  .recent-projects, .collaborations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    
  }
.caption {
  width: 80%;
}
  .BBM, .zodaily, .tutor-me {
    margin-left: 0rem;
  }
.mobile {
  margin-left: -4rem;
}
}
@media only screen and (max-width: 425px) {
  .mobile {
    margin-left: 18rem;
    margin-top: -10rem;
  }

  .live {
    margin-right: 2rem;
  }
  .laptop {
    margin-left: -3rem;
  }

  .caption {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .live {
    margin-right: .5rem;
  }

  .project-title {
    margin-left: 3rem;
  }
}
