* {
  margin: 0;
  padding: 0;
}

.about-page {
  background-color: #313340;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.about-me p {
  color: rgb(253, 19, 77, 0.5);
  font-size: 1.75rem;
  font-family: 'Oswald', sans-serif;
  width: 70%;
  margin-left: 20%;
  display: block;
  margin-top: 5rem;
  
}

.profile-picture {
  margin-right: 20%;
}
.profile-picture img {
  border-radius: 10%;
  height: 300px;
  width: 250px;
 
}

@media only screen and (max-width: 1280px) {
  .about-me p {
    font-size: 1.35rem;
  }

}

@media only screen and (max-width: 768px) {
  .about-me p {
    font-size: 1.5rem;
    margin-top: 7rem;
    text-align: center;
    padding-bottom: 3rem;
  }


  .profile-picture img {
    width: 200px;
    height: 250px;
    margin-left: 50%;
    
  }
  
  .about-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  align-items: center;
  }

}

@media only screen and (max-width: 425px) {
  .about-me {
    width: 100%;
  }
  .about-me p {
    font-size: 1.25rem;
  }

  .profile-picture img {
    width: 160px;
    height: 200px;
    
  }
  .profile-picture {
    margin-right: 30%;
  }
}

@media only screen and (max-width: 375px) {
  .profile-picture {
    margin-right: 35%;
  }

}
